<template>
  <div class="cnt-modal">
    <div>
      <ion-label class="fw-600 fs-3">{{ title }}</ion-label>
    </div>
    <div class="my-3 text-center">
      <ion-label>{{ subTitle }}</ion-label>
    </div>
    <ion-button expand="block" class="w-100" size="small" @click="$emit('on-yes')">{{
      $t('yes')
    }}</ion-button>
    <ion-button expand="block" class="w-100" size="small" color="grey6" @click="$emit('on-no')">{{
      $t('no')
    }}</ion-button>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    }
  },

  emits: ['on-no', 'on-yes']
});
</script>
<style lang="scss" scoped>
.cnt-modal {
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
</style>
